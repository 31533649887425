import {Routes} from '@angular/router'
import {LOGIN_ROUTE, registeredGuard} from '@ez-court/fe-theme'
import {ROUTE_DOCUMENTS} from './application/constants'

export const routes: Routes = [
  LOGIN_ROUTE,
  {
    path: ROUTE_DOCUMENTS,
    loadComponent: () => import('./ui/home/home.component').then(m => m.HomeComponent),
    canActivate: [registeredGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ROUTE_DOCUMENTS
  }
]
