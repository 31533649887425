<div class="main">
  <ezc-header
    [menu]="menu" [logoUrl]="logoUrl"
  ></ezc-header>

  <router-outlet></router-outlet>

  <ezc-footer></ezc-footer>
</div>
<mat-menu #menu>
  <a mat-menu-item href="./homepage/index.html">Min profil</a>
  <a mat-menu-item href="./documents/index.html">Mina dokument</a>
  <a mat-menu-item href="./scheduler/index.html">Skiljedom</a>
  <button mat-menu-item (click)="loginService.logout()">Logga ut</button>
</mat-menu>