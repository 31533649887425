import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest
} from '@angular/common/http'
import {inject} from '@angular/core'
import {LoginService} from '@ez-court/fe-theme'
import {Observable, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'


/**
 * Intended to catch e.g. 401 responses and if so re-route to home.
 */
export const responseInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const loginService = inject(LoginService)

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 || error.status === 403) {
        loginService.reset()
      }
      return throwError(() => error)
    })
  )
}